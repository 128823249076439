import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Progress } from "reactstrap";
import newId from "../../lib/utils/newId";
const baseStyle = {
  width: "100%",
  outline: 0,
  display: "inline-block"
};

class ImageDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: null,
      rejectedFiles: []
    };
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      rejectedFiles
    });
    var files = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        uniqId: newId("uploadImage_")
      })
    );
    this.props.handleUpload(files);
  }
  render() {
    const files = this.props.files
      ? this.props.files.map(file => (
          <div className="preview-wrapper" key={file.file.preview}>
            <div
              className="preview-image"
              style={{ backgroundImage: `url(${file.file.preview})` }}
            />
            <div className="preview-body">
              <h4>{file.file.name}</h4>
              <div>
                <h6 className="text-center mb-0 text-light">
                  {file.percentCompleted}%{" "}
                  {file.percentCompleted === 100 && !file.success
                    ? "Failed"
                    : "Uploaded"}
                </h6>
                <Progress
                  className="mb-0"
                  color={
                    file.percentCompleted === 100 && !file.success
                      ? "danger"
                      : "success"
                  }
                  value={file.percentCompleted}
                />
              </div>
            </div>
          </div>
        ))
      : null;

    const rejectedDiv = this.state.rejectedFiles.map((file, i) => (
      <div className="preview-wrapper" key={`rejectedFile-${i}`}>
        <div className="p-2">
          <h4>
            <span className="f-12 text-warning">不支持文件</span>
            {file.name}
          </h4>
        </div>
      </div>
    ));

    return (
      <div>
        <Dropzone accept="image/*" onDrop={this.onDrop.bind(this)}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div>
                <div {...getRootProps()} style={baseStyle}>
                  <input {...getInputProps()} />
                  <div className="dz-default dz-message">
                    <span>图片拖拽上传</span>
                  </div>
                </div>
                <div className="dz-preview">
                  {files}
                  {rejectedDiv}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default ImageDropzone;

ImageDropzone.propTypes = {
  handleUpload: PropTypes.func.isRequired
};
