import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { debounce } from "lodash";
import PropTypes from "prop-types";

class UsersDropdown extends React.Component {
  filterUsers() {
    return this.props.userOptions;
  }

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterUsers(inputValue));
    }, 1000);
  };

  handleSelectChange = name => value => {
    this.setState({
      [name]: value
    });
    this.props.onSelectChange(value);
  };

  handleInputChange = newValue => {
    // const inputValue = newValue.replace(/\W/g, "");
    this.props.onInputChange(newValue);
    return newValue;
  };

  render() {
    return (
      <div className={this.props.className}>
        <AsyncSelect
          id="inputUserID"
          placeholder="选择编辑"
          className="argon-select-container"
          classNamePrefix="react-select"
          isClearable={true}
          loadOptions={this.loadOptions}
          onInputChange={debounce(this.handleInputChange, 250)}
          value={this.props.selectedUser}
          onChange={this.handleSelectChange("selectedUser")}
          defaultOptions
        />
      </div>
    );
  }
}

export default UsersDropdown;

UsersDropdown.propTypes = {
  userOptions: PropTypes.array.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};
