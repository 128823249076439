import Axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { API_BASE_URL } from "./utils/config";
import store from "./store";
import { logout } from "./actions/authAction";

const baseURL = API_BASE_URL;
const TOKEN_KEY = "access_token";
class httpRequest {
  constructor() {
    this.options = {
      method: "",
      url: ""
    };
    // 存储请求队列
    this.queue = {};
  }
  // 销毁请求实例
  destroy(url) {
    delete this.queue[url];
    const queue = Object.keys(this.queue);
    return queue.length;
  }
  // 请求拦截
  interceptors(instance, url) {
    // 添加请求拦截器
    instance.interceptors.request.use(
      config => {
        // 在发送请求之前做些什么
        // 插入header身份TOKEN
        if (
          !config.url.includes("/login") &&
          !config.url.includes("/password")
        ) {
          config.headers["Authorization"] = "Bearer " + Cookies.get(TOKEN_KEY);
        }
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        return config;
      },
      error => {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );

    // 响应拦截
    instance.interceptors.response.use(
      res => {
        this.destroy(url);
        return res;
      },
      error => {
        this.destroy(url);
        // console.info("Oops, Error Code:", error.code);
        // console.info("Oops, Error Request", error.request);
        // console.info("Oops, Error Response", error.response);
        // console.info("Oops, Error Config", error.config);
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              error.message = "请求错误";
              toast.warn("Bad request.");
              break;

            case 401:
              error.message = "未授权，请登录";
              toast.warn("Please login.");
              store.dispatch(logout());
              break;

            case 403:
              error.message = "拒绝访问";
              toast.warn("403 Forbidden");
              break;

            case 404:
              error.message = `请求地址出错: ${error.response.config.url}`;
              toast.warn(`Page not found: ${error.response.config.url}`);
              break;

            case 408:
              error.message = "请求超时";
              toast.warn("408 Request Timeout");
              break;

            case 429:
              toast.warn("429 Too Many Requests");
              break;

            case 500:
              error.message = "服务器内部错误";
              toast.warn("500 Internal Server Error");
              break;

            case 501:
              error.message = "服务未实现";
              toast.warn("501 Not Implemented Error");
              break;

            case 502:
              error.message = "网关错误";
              toast.warn("502 Bad Gateway");
              break;

            case 503:
              error.message = "服务不可用";
              toast.warn("503 Service Unavailable");
              break;

            case 504:
              error.message = "网关超时";
              toast.warn("504 Gateway Timeout");
              break;

            case 505:
              error.message = "HTTP版本不受支持";
              toast.warn("505 HTTP Version Not Supported");
              break;

            default:
          }
        } else {
          if (!error.code) {
            // alert("Connection refused.");
          } else {
            if (error.code === "ECONNABORTED") {
              // alert(
              //   `Request timeout at ${
              //     error.config.url
              //   }. Please check your network.`
              // );
              toast.warn(
                `Request timeout at ${
                  error.config.url
                }. Please check your network.`
              );
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }
  // 创建实例
  create() {
    let conf = {
      baseURL: baseURL,
      timeout: 1800000,
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    };
    return Axios.create(conf);
  }
  // 请求实例
  request(options) {
    var instance = this.create();
    this.interceptors(instance, options.url);
    options = Object.assign({}, options);
    this.queue[options.url] = instance;
    return instance(options);
  }
}
export default httpRequest;
