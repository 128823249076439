import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class DeletePostModal extends React.Component {
  render() {
    const { selectedPost, openDeletePostModal } = this.props.postReducer;
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggleDeletePostModal({})}
      >
        <i className="fa fa-times" /> 关闭
      </button>
    );

    if (!selectedPost) {
      return null;
    }
    return (
      <Modal
        isOpen={openDeletePostModal}
        centered={true}
        contentClassName="bg-lightest"
        size="sm"
        toggle={() => this.props.toggleDeletePostModal({})}
      >
        <ModalHeader close={closeBtn}>确定永久删除该文章？</ModalHeader>
        <ModalBody>
          <p>文章：{selectedPost.title}</p>
          <p>编辑：{selectedPost.editor ? selectedPost.editor.name : ""}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => this.props.deletePost(selectedPost.id)}
          >
            删除
          </Button>
          <Button
            color="white"
            onClick={() => this.props.toggleDeletePostModal({})}
          >
            取消
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeletePostModal;
