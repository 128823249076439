import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import newId from "../../lib/utils/newId";
import "./SingleImageDropzone.scss";
const baseStyle = {
  width: "100%",
  outline: 0,
  display: "inline-block"
};

class ImageDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      rejectedFiles: []
    };
  }
  onDrop(acceptedFiles, rejectedFiles) {
    console.log(acceptedFiles);
    var files = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        uniqId: newId("uploadImage_")
      })
    );
    this.setState({
      rejectedFiles,
      files
    });
    this.props.handleUpload(files[0]);
  }
  render() {
    const rejectedDiv = this.state.rejectedFiles.map((file, i) => (
      <div className="preview-wrapper" key={`rejectedFile-${i}`}>
        <div className="p-2">
          <h4>
            <span className="f-12 text-warning">不支持文件</span>
            {file.name}
          </h4>
        </div>
      </div>
    ));

    return (
      <div>
        <Dropzone
          accept="image/*"
          multiple={false}
          onDrop={this.onDrop.bind(this)}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div style={{ position: "relative" }}>
                <div {...getRootProps()} style={baseStyle}>
                  <input {...getInputProps()} />

                  <div className="dz-preview dz-preview-single">
                    {this.state.files.length ? (
                      <div
                        className="preview-image"
                        style={{
                          backgroundImage: `url(${this.state.files[0].preview})`
                        }}
                      />
                    ) : this.props.src ? (
                      <div
                        className="preview-image"
                        style={{
                          backgroundImage: `url(${this.props.src})`
                        }}
                      />
                    ) : (
                      <div className="dz-default dz-message">
                        <span>图片拖拽上传</span>
                      </div>
                    )}
                    {rejectedDiv}
                  </div>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default ImageDropzone;

ImageDropzone.propTypes = {
  handleUpload: PropTypes.func.isRequired
};
