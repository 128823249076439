import {
  CATEGORY_GET_REQUEST,
  CATEGORY_GET_SUCCESS,
  CATEGORY_GET_FAIL
} from "../actions/categoriesAction";

const initialState = {
  loading: false,
  categories: null,
  errorMsg: "",
  successMsg: "",
  errors: {}
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_GET_REQUEST:
      return {
        ...state,
        loading: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case CATEGORY_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.data,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case CATEGORY_GET_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default categoryReducer;
