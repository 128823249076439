import React from "react";
import { Label, Input } from "reactstrap";
import newId from "../../lib/utils/newId";
class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      category_ids: []
    };
    this.toggle = this.toggle.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  componentDidMount() {
    const category_ids = this.props.category_ids ? this.props.category_ids : [];
    this.setState({
      ...this.state,
      category_ids
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.category_ids !== nextProps.category_ids) {
      const category_ids = nextProps.category_ids ? nextProps.category_ids : [];
      this.setState({
        category_ids
      });
    }
  }

  toggle() {
    this.setState({
      isVisible: !this.state.isVisible
    });
  }

  onCheckboxChange(e, value) {
    var category_ids = this.state.category_ids;
    if (e.target.checked) {
      //append to array
      category_ids = category_ids.concat([value]);
      // this.setState({
      //   category_ids: this.state.category_ids.concat([value])
      // });
    } else {
      category_ids = category_ids.filter(function(val) {
        return val !== value;
      });
    }
    this.setState({
      category_ids
    });
    this.props.onCategoryIdsChange(category_ids);
  }

  render() {
    // var icon = this.state.isVisible ? "-" : "+";
    var span;
    var nodes;
    var button;

    if (this.props.children.length) {
      //   span = (
      //     <span className="icon" onClick={this.toggle}>
      //       {this.props.node.name}
      //     </span>
      //   );

      //   if (this.state.isVisible) {
      nodes = this.props.children.map(i => (
        <TreeNode
          key={newId("cat-node")}
          node={i}
          children={i.children}
          category_ids={this.state.category_ids}
          onCategoryIdsChange={this.props.onCategoryIdsChange}
        />
      ));
      //   }
    } else {
      button = (
        <div className="custom-control custom-checkbox">
          <Input
            type="checkbox"
            className="custom-control-input"
            id={`category-id-${this.props.node.id}`}
            checked={this.state.category_ids.indexOf(this.props.node.id) > -1}
            onChange={e => this.onCheckboxChange(e, this.props.node.id)}
          />
          <Label
            className="custom-control-label"
            for={`category-id-${this.props.node.id}`}
          >
            {this.props.node.name}
          </Label>
        </div>
      );
    }

    return (
      <li>
        {span}
        {button}
        <ul>{nodes}</ul>
      </li>
    );
  }
}

export default TreeNode;
