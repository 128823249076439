import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../layout";
import {
  getPosts,
  createPost,
  publishPost,
  deletePost,
  toggleDeletePostModal
} from "../../lib/actions/postsAction";
import { getCategories } from "../../lib/actions/categoriesAction";
import { getUsers } from "../../lib/actions/usersAction";
import PostList from "../../components/posts/PostList";
import { push } from "connected-react-router";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.getPosts = this.getPosts.bind(this);

    this.state = {
      searchParams: {
        page: 1,
        category_ids: [],
        user_id: null
      }
    };
  }
  componentDidMount() {
    this.getPosts(this.state.searchParams);
    if (!this.props.categoryReducer.categories) {
      this.props.getCategories();
    }
    if (!this.props.userReducer.users) {
      this.props.getUsers({ page: 1, name: "" });
    }
  }
  getPosts(page) {
    let searchParams = {
      ...this.state.searchParams,
      page
    };
    this.setState({
      searchParams
    });
    this.props.getPosts(searchParams);
  }
  onCategoryIdsChange(category_ids) {
    let searchParams = {
      ...this.state.searchParams,
      page: 1,
      category_ids
    };
    this.setState({
      searchParams
    });
    this.props.getPosts(searchParams);
  }

  onUserChange(value) {
    let searchParams = {
      ...this.state.searchParams,
      page: 1,
      user_id: value
    };
    this.setState({
      searchParams
    });
    this.props.getPosts(searchParams);
  }

  render() {
    return (
      <Layout>
        <div>
          {this.props.postReducer.posts ? (
            <PostList
              {...this.props}
              category_ids={this.state.searchParams.category_ids}
              goToPage={this.getPosts}
              onCategoryIdsChange={this.onCategoryIdsChange.bind(this)}
              onUserChange={this.onUserChange.bind(this)}
            />
          ) : null}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { postReducer, categoryReducer, userReducer, authReducer } = state;
  return {
    postReducer,
    categoryReducer,
    userReducer,
    authReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPosts: params => getPosts(params),
      createPost: () => createPost(),
      goNewPost: () => push("/posts/new"),
      goToPost: post_id => push("posts/edit/" + post_id),
      publishPost: post_id => publishPost(post_id),
      deletePost: post_id => deletePost(post_id),
      toggleDeletePostModal: post => toggleDeletePostModal(post),
      getCategories: () => getCategories(),
      getUsers: ({ page, name }) => getUsers({ page, name })
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
