import React from "react";
import {
  Form,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      password: "",
      password_confirmation: ""
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { old_password, password, password_confirmation } = this.state;
    this.props.changePassword({
      old_password,
      password,
      password_confirmation
    });
  }

  render() {
    const { authReducer } = this.props;
    const { errors, updatingProfile, errorMsg } = authReducer;

    return (
      <Card className="main-card">
        <Form onSubmit={this.onSubmit}>
          <CardHeader>
            <h3>修改密码</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} className="m-auto">
                <p className="text-warning text-center f-14">{errorMsg}</p>
                <FormGroup>
                  <Label for="inputOldPassword">旧密码</Label>
                  <Input
                    type="password"
                    name="old_password"
                    id="inputOldPassword"
                    placeholder="xxxxxx"
                    className="input-group-alternative"
                    value={this.state.old_password}
                    onChange={this.onInputChange}
                    invalid={!errors.old_password === false}
                    autoComplete="off"
                  />
                  <FormFeedback invalid={errors.old_password}>
                    {errors.old_password}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputPassword">新密码</Label>
                  <Input
                    type="password"
                    name="password"
                    id="inputPassword"
                    placeholder="xxxxxx"
                    className="input-group-alternative"
                    value={this.state.password}
                    onChange={this.onInputChange}
                    invalid={!errors.password === false}
                    autoComplete="off"
                  />
                  <FormFeedback invalid={errors.password}>
                    {errors.password}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputPassword2">确认密码</Label>
                  <Input
                    type="password"
                    name="password_confirmation"
                    id="inputPassword2"
                    placeholder="xxxxxx"
                    className="input-group-alternative"
                    value={this.state.password_confirmation}
                    onChange={this.onInputChange}
                    invalid={!errors.password === false}
                    autoComplete="off"
                  />
                  <FormFeedback invalid={errors.password}>
                    {errors.password}
                  </FormFeedback>
                </FormGroup>
                <Button
                  type="submit"
                  className="w-100 d-block mt-5"
                  color="info"
                  disabled={updatingProfile}
                >
                  {updatingProfile ? "处理中..." : "更改密码"}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Form>
      </Card>
    );
  }
}

export default ChangePassword;
