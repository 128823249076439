import React from "react";
import {
  Form,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap";

class UpdateProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      first_name: "",
      last_name: "",
      bio: ""
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.props.authReducer.profile
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.authReducer.profile !== nextProps.authReducer.profile) {
      this.setState({
        ...nextProps.authReducer.profile
      });
    }
  }
  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { email, name, first_name, last_name, bio } = this.state;
    this.props.updateProfile({ email, name, first_name, last_name, bio });
  }

  render() {
    const { authReducer } = this.props;
    const { errors, updatingProfile, errorMsg } = authReducer;

    return (
      <Card className="main-card">
        <Form onSubmit={this.onSubmit}>
          <CardHeader>
            <h3>修改个人资料</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} className="m-auto">
                <p className="text-warning text-center f-14">{errorMsg}</p>
                <FormGroup>
                  <Label for="inputEmail">邮箱</Label>
                  <Input
                    type="email"
                    name="email"
                    id="inputEmail"
                    placeholder="carol@gmail.com"
                    className="input-group-alternative"
                    value={this.state.email}
                    onChange={this.onInputChange}
                    invalid={!errors.email === false}
                  />
                  <FormFeedback invalid={errors.email}>
                    {errors.email}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputFirstName">名字</Label>
                  <Input
                    type="text"
                    name="first_name"
                    id="inputFirstName"
                    placeholder="e.g. Carol"
                    className="input-group-alternative"
                    value={this.state.first_name}
                    onChange={this.onInputChange}
                    invalid={!errors.first_name === false}
                  />
                  <FormFeedback invalid={errors.first_name}>
                    {errors.first_name}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputLastName">姓氏</Label>
                  <Input
                    type="text"
                    name="last_name"
                    id="inputLastName"
                    placeholder="e.g. Chen"
                    className="input-group-alternative"
                    value={this.state.last_name}
                    onChange={this.onInputChange}
                    invalid={!errors.last_name === false}
                  />
                  <FormFeedback invalid={errors.last_name}>
                    {errors.last_name}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputName">笔名</Label>
                  <Input
                    type="text"
                    name="name"
                    id="inputName"
                    placeholder="输入作者笔名"
                    className="input-group-alternative"
                    value={this.state.name}
                    onChange={this.onInputChange}
                    invalid={!errors.name === false}
                  />
                  <FormFeedback invalid={errors.name}>
                    {errors.name}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputBio">自我介绍</Label>
                  <Input
                    type="textarea"
                    name="bio"
                    id="inputBio"
                    placeholder="..."
                    className="input-group-alternative"
                    value={this.state.bio}
                    onChange={this.onInputChange}
                    invalid={!errors.bio === false}
                  />
                  <FormFeedback invalid={errors.bio}>{errors.bio}</FormFeedback>
                </FormGroup>

                <Button
                  type="submit"
                  className="w-100 d-block"
                  color="info"
                  disabled={updatingProfile}
                >
                  {updatingProfile ? "处理中..." : "更新"}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Form>
      </Card>
    );
  }
}

export default UpdateProfileForm;
