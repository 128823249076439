import Api from "../api.request";
import { toast } from "react-toastify";

export const ADS_GET_ADS_REQUEST = "ADS_GET_ADS_REQUEST";
export const ADS_GET_ADS_SUCCESS = "ADS_GET_ADS_SUCCESS";
export const ADS_GET_ADS_FAIL = "ADS_GET_ADS_FAIL";
export const getAds = () => dispatch => {
  dispatch({ type: ADS_GET_ADS_REQUEST });
  Api.request({
    url: "ads",
    params: {},
    method: "get"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: ADS_GET_ADS_SUCCESS,
        msg: data.message,
        ads: data.data
      });
    } else {
      dispatch({
        type: ADS_GET_ADS_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const ADS_TOGGLE_AD_MODAL = "ADS_TOGGLE_AD_MODAL";
export const toggleAdModal = ad => dispatch => {
  dispatch({
    type: ADS_TOGGLE_AD_MODAL,
    selectedAd: ad ? ad : {}
  });
};

export const ADS_CREATE_AD_REQUEST = "ADS_CREATE_AD_REQUEST";
export const ADS_CREATE_AD_SUCCESS = "ADS_CREATE_AD_SUCCESS";
export const ADS_CREATE_AD_FAIL = "ADS_CREATE_AD_FAIL";
export const createAd = ({
  company_name,
  file,
  display_section_id,
  active,
  link_to
}) => dispatch => {
  dispatch({ type: ADS_CREATE_AD_REQUEST });
  let formData = new FormData();
  formData.append("company_name", company_name);
  formData.append("display_section_id", display_section_id);
  if (active) formData.append("active", active);
  formData.append("link_to", link_to);
  formData.append("file", file);
  Api.request({
    url: "create-ad",
    data: formData,
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: ADS_CREATE_AD_SUCCESS, msg: data.message });
      dispatch(getAds({ page: 1 }));
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      dispatch({
        type: ADS_CREATE_AD_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const ADS_UPDATE_AD_REQUEST = "ADS_UPDATE_AD_REQUEST";
export const ADS_UPDATE_AD_SUCCESS = "ADS_UPDATE_AD_SUCCESS";
export const ADS_UPDATE_AD_FAIL = "ADS_UPDATEAD_FAIL";
export const updateAd = ({
  id,
  company_name,
  file,
  display_section_id,
  active,
  link_to
}) => dispatch => {
  dispatch({ type: ADS_UPDATE_AD_REQUEST });
  let formData = new FormData();
  formData.append("id", id);
  formData.append("company_name", company_name);
  formData.append("display_section_id", display_section_id);
  if (active) formData.append("active", active);
  formData.append("link_to", link_to);
  if (file) {
    formData.append("file", file);
  }

  Api.request({
    url: "update-ad",
    data: formData,
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: ADS_UPDATE_AD_SUCCESS, msg: data.message });
      dispatch(getAds({ page: 1 }));
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      dispatch({
        type: ADS_UPDATE_AD_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const ADS_DELETE_AD_REQUEST = "ADS_DELETE_AD_REQUEST";
export const ADS_DELETE_AD_DONE = "ADS_DELETE_AD_DONE";
export const deleteAd = id => dispatch => {
  dispatch({ type: ADS_DELETE_AD_REQUEST });
  Api.request({
    url: "delete-ad",
    data: { id },
    method: "POST"
  }).then(({ data }) => {
    dispatch({ type: ADS_DELETE_AD_DONE, msg: data.message });
    if (data.success) {
      dispatch(getAds({ page: 1 }));
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.warn(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  });
};

export const ADS_REORDER_REQUEST = "ADS_REORDER_REQUEST";
export const ADS_REORDER_SUCCESS = "ADS_REORDER_SUCCESS";
export const ADS_REORDER_FAIL = "ADS_REORDER_FAIL";
export const reorderAd = (
  id,
  display_section_id,
  source_order,
  destination_order
) => dispatch => {
  dispatch({ type: ADS_REORDER_REQUEST });
  Api.request({
    url: "reorder-ad",
    data: { id, display_section_id, source_order, destination_order },
    method: "POST"
  }).then(({ data }) => {
    dispatch(getAds());
    if (data.success) {
      dispatch({ type: ADS_REORDER_SUCCESS, msg: data.message });
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      dispatch({
        type: ADS_REORDER_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  });
};

export const ADS_GET_DISPLAY_SECTIONS_REQUEST =
  "ADS_GET_DISPLAY_SECTIONS_REQUEST";
export const ADS_GET_DISPLAY_SECTIONS_SUCCESS =
  "ADS_GET_DISPLAY_SECTIONS_SUCCESS";
export const ADS_GET_DISPLAY_SECTIONS_FAIL = "ADS_GET_DISPLAY_SECTIONS_FAIL";
export const getDisplaySections = () => dispatch => {
  dispatch({ type: ADS_GET_DISPLAY_SECTIONS_REQUEST });
  Api.request({
    url: "display-sections",
    params: {},
    method: "GET"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: ADS_GET_DISPLAY_SECTIONS_SUCCESS,
        msg: data.message,
        data: data.data
      });
    } else {
      dispatch({
        type: ADS_GET_DISPLAY_SECTIONS_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const ADS_TOGGLE_DELETE_AD_MODAL = "ADS_TOGGLE_DELETE_AD_MODAL";
export const toggleDeleteAdModal = ad => dispatch => {
  dispatch({
    type: ADS_TOGGLE_DELETE_AD_MODAL,
    selectedAd: ad
  });
};
