import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Logo from "../../assets/fdmedia_logo.svg";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

    this.renderAdminDiv = this.renderAdminDiv.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderAdminDiv(comp) {
    let manageStaff = null;
    const { profile } = this.props.auth;
    if (profile) {
      if (profile.role_id === 1) {
        manageStaff = (
          <NavItem>
            <Link className="nav-link" to="/users">
              员工管理
            </Link>
          </NavItem>
        );
      }
    }
    switch (comp) {
      case "manageStaff":
        return manageStaff;
      default:
        return null;
    }
  }
  render() {
    const { profile } = this.props.auth;
    return (
      <Navbar
        className="navbar-vertical fixed-left"
        color="default"
        dark
        expand="lg"
      >
        <NavbarBrand href="/">
          <img
            src={Logo}
            className="navbar-brand-img"
            alt="Optima Ninja Care Logo"
          />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link className="nav-link" to="/">
                新闻编辑
              </Link>
            </NavItem>

            <NavItem>
              <Link className="nav-link" to="/ads">
                广告管理
              </Link>
            </NavItem>

            {this.renderAdminDiv("manageStaff")}

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Hi, {profile ? profile.name : ""}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <div onClick={() => this.props.goNewPage("/profile")}>
                    个人资料
                  </div>
                </DropdownItem>

                <DropdownItem>
                  <div onClick={() => this.props.goNewPage("/change-password")}>
                    修改密码
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <div onClick={this.props.logout}>退出</div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
