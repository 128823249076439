import React from "react";
import {
  Form,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Button
} from "reactstrap";

class LgoinForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onInput(e, name) {
    this.setState({
      [name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.handleLogin(email, password);
  }

  render() {
    const { authReducer } = this.props;
    const { errors, loading, errorMsg } = authReducer;
    return (
      <div>
        <p className="text-warning text-center f-14">{errorMsg}</p>
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="inputEmail">邮箱</Label>
            <Input
              type="email"
              name="email"
              id="inputEmail"
              placeholder="carol@gmail.com"
              className="input-group-alternative"
              value={this.state.email}
              onChange={this.onInputChange}
              invalid={!errors.email === false}
            />
            <FormFeedback invalid={errors.email}>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="inputPassword">密码</Label>
            <Input
              type="password"
              name="password"
              id="inputPassword"
              placeholder="xxxxxx"
              className="input-group-alternative"
              value={this.state.password}
              onChange={this.onInputChange}
              invalid={!errors.password === false}
              autoComplete="off"
            />
            <FormFeedback invalid={errors.password}>
              {errors.password}
            </FormFeedback>
          </FormGroup>
          <Button
            type="submit"
            className="w-100 d-block"
            color="info"
            disabled={loading}
          >
            {loading ? "处理中..." : "登陆"}
          </Button>
        </Form>
      </div>
    );
  }
}

export default LgoinForm;
