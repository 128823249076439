import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../layout";
import {
  getAds,
  toggleAdModal,
  createAd,
  updateAd,
  deleteAd,
  reorderAd,
  getDisplaySections,
  toggleDeleteAdModal
} from "../../lib/actions/adsAction";
import AdList from "../../components/ads/AdList";
import AdModal from "../../components/ads/AdModal";
import DeleteAdModal from "../../components/ads/DeleteAdModal";
class Ads extends React.Component {
  componentDidMount() {
    this.props.getAds();
  }

  render() {
    return (
      <Layout>
        <div>
          <AdModal {...this.props} />
          <DeleteAdModal {...this.props} />
          {this.props.adReducer.ads ? (
            <AdList
              ads={this.props.adReducer.ads}
              goToPage={this.getAds}
              {...this.props}
            />
          ) : null}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { adReducer } = state;
  return {
    adReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAds: () => getAds(),
      toggleAdModal: ad => toggleAdModal(ad),
      createAd: params => createAd(params),
      updateAd: params => updateAd(params),
      deleteAd: id => deleteAd(id),
      reorderAd: (id, display_section_id, source_order, destination_order) =>
        reorderAd(id, display_section_id, source_order, destination_order),
      getDisplaySections: () => getDisplaySections(),
      toggleDeleteAdModal: ad => toggleDeleteAdModal(ad)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ads);
