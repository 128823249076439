import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class DeleteAdModal extends React.Component {
  render() {
    const { selectedAd, openDeleteAdModal } = this.props.adReducer;
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggleDeleteAdModal({})}
      >
        <i className="fa fa-times" /> 关闭
      </button>
    );
    return (
      <Modal
        isOpen={openDeleteAdModal}
        centered={true}
        contentClassName="bg-lightest"
        size="sm"
        toggle={() => this.props.toggleDeleteAdModal({})}
      >
        <ModalHeader close={closeBtn}>确定永久删除该广告？</ModalHeader>
        <ModalBody>
          <p>广告：{selectedAd.company_name}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => this.props.deleteAd(selectedAd.id)}
          >
            删除
          </Button>
          <Button
            color="white"
            onClick={() => this.props.toggleDeleteAdModal({})}
          >
            取消
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteAdModal;
