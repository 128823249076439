import React from "react";
import TreeNode from "./TreeNode";
import newId from "../../lib/utils/newId";

class TreeView extends React.Component {
  render() {
    const { categories, category_ids } = this.props;
    var nodes = categories.map(i => (
      <TreeNode
        key={newId("cat-tree")}
        node={i}
        children={i.children}
        category_ids={category_ids}
        onCategoryIdsChange={this.props.onCategoryIdsChange}
      />
    ));
    return (
      <div className="category-tree">
        <ul>{nodes}</ul>
      </div>
    );
  }
}
export default TreeView;
