import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { changePassword } from "../../lib/actions/authAction";
import Layout from "../layout";
import ChangePassword from "../../components/profile/ChangePassword";

class Password extends React.Component {
  render() {
    return (
      <Layout>
        <div class="container-fluid">
          <ChangePassword {...this.props} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { authReducer } = state;
  return {
    authReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goNewPage: path => push(path),
      changePassword: params => changePassword(params)
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Password);
