import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./GlobalToast.scss";

const Close = ({ closeToast }) => (
  <button className="close" aria-label="Close" onClick={closeToast}>
    <span aria-hidden="true">×</span>
  </button>
);

class GlobalToast extends React.Component {
  render() {
    return <ToastContainer autoClose={5000} closeButton={<Close />} />;
  }
}

export default GlobalToast;
