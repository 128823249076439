import React from "react";
import { Card, Row, Col, CardHeader, Button } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AdItem from "./AdItem";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "#eee" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#eee" : "#fff"
});

export default class AdList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ads: null
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updateSectionOrder = this.updateSectionOrder.bind(this);
  }

  componentDidMount() {
    this.setState({
      ads: this.props.ads
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ads !== this.props.ads) {
      this.setState({
        ads: nextProps.ads
      });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const id = result.draggableId;
    const display_section_id = result.destination.droppableId.replace(
      "section-",
      ""
    );
    const source_order = result.source.index;
    const destination_order = result.destination.index;
    this.props.reorderAd(
      id,
      display_section_id,
      source_order,
      destination_order
    );
    this.updateSectionOrder(
      display_section_id,
      source_order,
      destination_order
    );
  }

  updateSectionOrder(display_section_id, source_order, destination_order) {
    const { ads } = this.state;
    const orginalAds = [...ads];
    const filterSections = orginalAds.filter(section => {
      return section.id === parseInt(display_section_id);
    });
    // console.log(orginalAds);
    if (filterSections.length > 0) {
      var targetSection = filterSections[0];
      var result = [...targetSection.advertisements];
      const indexOfTargetSection = orginalAds.indexOf(targetSection);
      // console.log(indexOfTargetSection);
      const [removed] = result.splice(source_order, 1);
      // console.log(removed);
      result.splice(destination_order, 0, removed);
      // console.log(result);
      targetSection.advertisements = result;
      orginalAds[indexOfTargetSection] = targetSection;
      // console.log(orginalAds);
      this.setState({
        ads: orginalAds
      });
    }
  }

  render() {
    const { ads } = this.state;

    return (
      <div className="container-fluid">
        <Row className="my-3">
          <Col md={4} sm={6}>
            <h3 className="mb-0">广告管理</h3>
          </Col>
          <Col md={8} sm={6} className="text-right">
            <Button color="info" onClick={this.props.toggleAdModal}>
              创建新广告
            </Button>
          </Col>
        </Row>
        <Row>
          {ads
            ? ads.map(section => (
                <Col xl={4} key={`sec-${section.id}`}>
                  <Card className="shadow border-0 border-l my-2">
                    <CardHeader>
                      <h3 className="mb-0">{section.name}</h3>
                    </CardHeader>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId={`section-${section.id}`}>
                        {(provided, snapshot) => (
                          <ul
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            className="list-group list-group-flush list"
                          >
                            {section.advertisements.map((ad, index) => (
                              <Draggable
                                key={ad.id}
                                draggableId={ad.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <AdItem
                                      ad={ad}
                                      toggleAdModal={this.props.toggleAdModal}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Card>
                </Col>
              ))
            : null}
        </Row>
      </div>
    );
  }
}
