import {
  POST_GET_POSTS_REQUEST,
  POST_GET_POSTS_SUCCESS,
  POST_GET_POSTS_FAIL,
  POST_GET_POST_REQUEST,
  POST_GET_POST_SUCCESS,
  POST_GET_POST_FAIL,
  POST_UPLOAD_IMAGE_STATUS,
  POST_BATCH_UPLOAD_IMAGES,
  POST_UPDATE_POST_REQUEST,
  POST_UPDATE_POST_SUCCESS,
  POST_UPDATE_POST_FAIL,
  POST_TOGGLE_DELETE_POST_MODAL,
  POST_DELETE_POST_REQUEST,
  POST_DELETE_POST_SUCCESS,
  POST_DELETE_POST_FAIL,
  POST_CLEAR_UPLOAD_IMAGES
} from "../actions/postsAction";

const initialState = {
  posts: null,
  loading: false,
  updatingPost: false,
  uploadImages: null,
  uploadImage: {
    percentCompleted: 0,
    success: false
  },
  successMsg: "",
  errorMsg: "",
  errors: {},
  selectedPost: {},
  openDeletePostModal: false
};

const post = (state = initialState, action) => {
  switch (action.type) {
    case POST_GET_POSTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case POST_GET_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.users,
        loading: false
      };
    case POST_GET_POSTS_FAIL:
      return {
        ...state,
        loading: false,
        selectedPost: {}
      };
    case POST_GET_POST_REQUEST:
      return {
        ...state,
        loading: true,
        successMsg: "",
        errorMsg: "",
        errors: {},
        selectedPost: {}
      };
    case POST_GET_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPost: action.post,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case POST_GET_POST_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case POST_UPDATE_POST_REQUEST:
      return {
        ...state,
        updatingPost: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case POST_UPDATE_POST_SUCCESS:
      return {
        ...state,
        updatingPost: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {},
        selectedPost: action.post
      };
    case POST_UPDATE_POST_FAIL:
      return {
        ...state,
        updatingPost: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case POST_BATCH_UPLOAD_IMAGES:
      var list = state.uploadImages ? state.uploadImages : [];
      for (var i = 0; i < action.images.length; i++) {
        var item = {
          file: action.images[i],
          percentCompleted: 0,
          success: false
        };
        list.unshift(item);
      }
      return {
        ...state,
        uploadImages: list
      };
    case POST_UPLOAD_IMAGE_STATUS:
      var files = [...state.uploadImages];
      var file = {
        file: action.file,
        percentCompleted: action.percentCompleted,
        success: action.success
      };
      for (var x = 0; x < files.length; x++) {
        if (files[x].file === action.file) {
          files[x] = file;
        }
      }
      return {
        ...state,
        uploadImages: files
      };
    case POST_TOGGLE_DELETE_POST_MODAL:
      return {
        ...state,
        openDeletePostModal: !state.openDeletePostModal,
        selectedPost: action.selectedPost,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case POST_DELETE_POST_REQUEST:
      return {
        ...state,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case POST_DELETE_POST_SUCCESS:
      return {
        ...state,
        openDeletePostModal: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case POST_DELETE_POST_FAIL:
      return {
        ...state,
        openDeletePostModal: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case POST_CLEAR_UPLOAD_IMAGES:
      return {
        ...state,
        uploadImages: null
      };
    default:
      return state;
  }
};

export default post;
