import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../layout";
import {
  batchUploadImages,
  clearUploadImage
} from "../../lib/actions/postsAction";
import EditPost from "../../components/posts/EditPost";

class NewPost extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Layout>
        <div>
          <EditPost {...this.props} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { postReducer } = state;
  return {
    postReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      batchUploadImages: ({ post_id, files }) =>
        batchUploadImages({ post_id, files }),
      clearUploadImage: () => clearUploadImage()
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPost);
