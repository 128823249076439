import Cookies from "js-cookie";
import Api from "../api.request";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

export const AUTH_LOG_IN_REQUEST = "AUTH_LOG_IN_REQUEST";
export const AUTH_LOG_IN_SUCCESS = "AUTH_LOG_IN_SUCCESS";
export const AUTH_LOG_IN_FAIL = "AUTH_LOG_IN_FAIL";
export const login = (email, password) => dispatch => {
  dispatch({ type: AUTH_LOG_IN_REQUEST });
  Api.request({
    url: "auth/login",
    params: {
      email,
      password
    },
    method: "post"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: AUTH_LOG_IN_SUCCESS });
      dispatch(
        redirectAfterAuthed(data.data.access_token, data.data.expires_at)
      );
    } else {
      dispatch({
        type: AUTH_LOG_IN_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const redirectAfterAuthed = (access_token, expires_at) => dispatch => {
  Cookies.set("access_token", access_token);
  Cookies.set("expires_at", expires_at);
  var redirectTo = Cookies.get("redirectTo");
  if (redirectTo) {
    Cookies.remove("redirectTo");
    dispatch(push(redirectTo));
  } else {
    // window.location.href = "/";
    dispatch(push("/"));
  }
};

export const AUTH_GET_PROFILE_REQUEST = "AUTH_GET_PROFILE_REQUEST";
export const AUTH_GET_PROFILE_SUCCESS = "AUTH_GET_PROFILE_SUCCESS";
export const AUTH_GET_PROFILE_FAIL = "AUTH_GET_PROFILE_FAIL";
export const getProfile = () => dispatch => {
  dispatch({ type: AUTH_GET_PROFILE_REQUEST });
  Api.request({
    url: "auth/user"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: AUTH_GET_PROFILE_SUCCESS,
        profile: data.data,
        msg: data.message
      });
    } else {
      dispatch({
        type: AUTH_GET_PROFILE_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const AUTH_LOG_OUT = "AUTH_LOG_OUT";
export const logout = () => dispatch => {
  dispatch({ type: AUTH_LOG_OUT });
  Api.request({
    url: "auth/logout",
    method: "get"
  }).then(({ data }) => {
    if (data.success) {
      Cookies.remove("access_token");
      Cookies.remove("expires_at");
    }
    window.location.href = "/login";
  });
};

export const AUTH_FORGOT_PASSWORD_REQUEST = "AUTH_FORGOT_PASSWORD_REQUEST";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAIL = "AUTH_FORGOT_PASSWORD_FAIL";
export const forgotPassword = email => dispatch => {
  dispatch({ type: AUTH_FORGOT_PASSWORD_REQUEST });
  Api.request({
    url: "password/create",
    data: { email },
    method: "post"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: AUTH_FORGOT_PASSWORD_SUCCESS, msg: data.message });
      toast.success(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } else {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  });
};

export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAIL = "AUTH_RESET_PASSWORD_FAIL";
export const resetPassword = (
  email,
  password,
  password_confirmation,
  token
) => dispatch => {
  dispatch({ type: AUTH_RESET_PASSWORD_REQUEST });
  Api.request({
    url: "password/reset",
    data: { email, password, password_confirmation, token },
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: AUTH_RESET_PASSWORD_SUCCESS, msg: data.message });
      toast.success(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      dispatch(
        redirectAfterAuthed(data.data.access_token, data.data.expires_at)
      );
    } else {
      dispatch({
        type: AUTH_RESET_PASSWORD_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  });
};

export const AUTH_CHANGE_PASSWORD_REQUEST = "AUTH_CHANGE_PASSWORD_REQUEST";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAIL = "AUTH_CHANGE_PASSWORD_FAIL";
export const changePassword = ({
  old_password,
  password,
  password_confirmation
}) => dispatch => {
  dispatch({ type: AUTH_CHANGE_PASSWORD_REQUEST });
  Api.request({
    url: "change-password",
    data: { old_password, password, password_confirmation },
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: AUTH_CHANGE_PASSWORD_SUCCESS, msg: data.message });
      toast.success(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } else {
      dispatch({
        type: AUTH_CHANGE_PASSWORD_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  });
};

export const AUTH_UPDATE_PROFILE_REQUEST = "AUTH_UPDATE_PROFILE_REQUEST";
export const AUTH_UPDATE_PROFILE_SUCCESS = "AUTH_UPDATE_PROFILE_SUCCESS";
export const AUTH_UPDATE_PROFILE_FAIL = "AUTH_UPDATE_PROFILE_FAIL";
export const updateProfile = ({
  name,
  first_name,
  last_name,
  email,
  bio
}) => dispatch => {
  dispatch({ type: AUTH_UPDATE_PROFILE_REQUEST });
  Api.request({
    url: "update-profile",
    data: { name, first_name, last_name, email, bio },
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: AUTH_UPDATE_PROFILE_SUCCESS,
        msg: data.message,
        user: data.data
      });
      toast.success(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } else {
      dispatch({
        type: AUTH_UPDATE_PROFILE_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  });
};
