import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../layout/auth";
import { resetPassword } from "../../lib/actions/authAction";
import ResetForm from "../../components/password/ResetForm";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { push } from "connected-react-router";

class ResetPassword extends React.Component {
  render() {
    const centerStyle = {
      height: "100vh",
      alignItems: "center"
    };

    return (
      <Layout>
        <Row className="justify-content-center" style={centerStyle}>
          <Col md={5}>
            <Card className="border-0 mb-0 shadow">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>重置密码</small>
                </div>
                <ResetForm {...this.props} />
              </CardBody>
            </Card>
            <Row className=" mt-3">
              <Col className="text-center">
                <Button
                  color="link"
                  className="text-white"
                  onClick={this.props.goLogin}
                >
                  <small>返回登陆</small>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword: (email, password, password_confirmation, token) =>
        resetPassword(email, password, password_confirmation, token),
      goLogin: () => push("/login")
    },
    dispatch
  );

const mapStateToProps = state => {
  const { authReducer } = state;
  return {
    authReducer
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
