import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../layout";
import {
  batchUploadImages,
  getPost,
  updatePost,
  clearUploadImage
} from "../../lib/actions/postsAction";
import { getCategories } from "../../lib/actions/categoriesAction";
import EditPost from "../../components/posts/EditPost";
import ErrorLoadingCard from "../../components/common/ErrorLoadingCard";
import { push } from "connected-react-router";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
    };
  }

  componentDidMount() {
    this.props.getPost(this.state.id);
  }

  render() {
    const { id } = this.state;
    const { loading, errorMsg } = this.props.postReducer;
    return (
      <Layout>
        <div className="container-fluid">
          {loading || !id ? (
            <ErrorLoadingCard loading={loading} error={errorMsg} />
          ) : (
            <EditPost {...this.props} />
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { postReducer, categoryReducer } = state;
  return {
    postReducer,
    categoryReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      batchUploadImages: ({ post_id, files }) =>
        batchUploadImages({ post_id, files }),
      getPost: post_id => getPost(post_id),
      updatePost: data => updatePost(data),
      getCategories: () => getCategories(),
      goPostList: () => push("/"),
      clearUploadImage: () => clearUploadImage()
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
