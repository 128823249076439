import Api from "../api.request";

export const CATEGORY_GET_REQUEST = "CATEGORY_GET_REQUEST";
export const CATEGORY_GET_SUCCESS = "CATEGORY_GET_SUCCESS";
export const CATEGORY_GET_FAIL = "CATEGORY_GET_FAIL";
export const getCategories = () => dispatch => {
  dispatch({ type: CATEGORY_GET_REQUEST });
  Api.request({
    url: "categories",
    params: {},
    method: "GET"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: CATEGORY_GET_SUCCESS,
        msg: data.message,
        data: data.data
      });
    } else {
      dispatch({
        type: CATEGORY_GET_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};
