import React from "react";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  CustomInput,
  FormFeedback
} from "reactstrap";
import ImageDropzone from "../common/ImageDropzone";
import Moment from "react-moment";
import Affix from "../common/Affix";
import "./EditPost.scss";
import PostEditor from "./PostEditor";
import CoverEditor from "./CoverEditor";
import TreeView from "../categories/TreeView";
import { PREVIEW_BASE_URL } from "../../lib/utils/config";

class PostEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      originalPost: {},
      id: "",
      title: "",
      preface: "",
      body: "",
      author: "",
      user_id: "",
      coverImg: "",
      active: false,
      posted_at: "",
      category_ids: [],
      youtube_video: ""
    };

    this.onBodyChange = this.onBodyChange.bind(this);
    this.onCoverChange = this.onCoverChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.batchUpload = this.batchUpload.bind(this);
    this.savePost = this.savePost.bind(this);
    this.saveAndExit = this.saveAndExit.bind(this);
    this.onActiveChange = this.onActiveChange.bind(this);
    this.onCategoryIdsChange = this.onCategoryIdsChange.bind(this);
    this.setSeletedPost = this.setSeletedPost.bind(this);
  }

  setSeletedPost(newSeletedPost) {
    const {
      id,
      title,
      preface,
      body,
      author,
      user_id,
      coverImg,
      active,
      posted_at,
      category_ids,
      youtube_video
    } = newSeletedPost;
    this.setState({
      originalPost: newSeletedPost,
      id: id,
      title: title ? title : "",
      preface: preface ? preface : "",
      body: body ? body : "",
      author: author ? author : "",
      user_id: user_id ? user_id : null,
      coverImg: coverImg ? coverImg : "",
      active: active ? active : false,
      posted_at: posted_at ? posted_at : "",
      category_ids: category_ids ? category_ids : [],
      youtube_video: youtube_video ? youtube_video : ""
    });
  }

  componentDidMount() {
    if (!this.props.categoryReducer.categories) {
      this.props.getCategories();
    }
    this.setSeletedPost(this.props.postReducer.selectedPost);
    this.interval = setInterval(() => this.savePost(), 15000);
  }

  componentWillUnmount() {
    this.props.clearUploadImage();
    clearInterval(this.interval);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.postReducer.selectedPost !== this.props.postReducer.selectedPost
  //   ) {
  //     this.setSeletedPost(nextProps.postReducer.selectedPost);
  //   }
  // }

  batchUpload(files) {
    this.props.batchUploadImages({
      post_id: this.props.match.params.id,
      files
    });
  }

  onBodyChange(text) {
    this.setState({
      body: text
    });
  }

  onCoverChange(src) {
    this.setState({
      coverImg: src
    });
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onActiveChange(active) {
    this.setState({
      active: active
    });
  }

  onCategoryIdsChange(list) {
    this.setState({
      category_ids: list
    });
  }

  goback() {
    this.props.goPostList();
  }

  savePost() {
    const {
      id,
      title,
      preface,
      body,
      author,
      user_id,
      coverImg,
      active,
      posted_at,
      category_ids,
      youtube_video
    } = this.state;
    this.props.updatePost({
      id,
      title,
      preface,
      body,
      author,
      user_id,
      coverImg,
      active,
      posted_at,
      category_ids,
      youtube_video,
      goback: false
    });
  }

  saveAndExit() {
    const {
      id,
      title,
      preface,
      body,
      author,
      user_id,
      coverImg,
      active,
      posted_at,
      category_ids,
      youtube_video
    } = this.state;
    this.props.updatePost({
      id,
      title,
      preface,
      body,
      author,
      user_id,
      coverImg,
      active,
      posted_at,
      category_ids,
      youtube_video,
      goback: true
    });
  }

  render() {
    const { updatingPost, errors } = this.props.postReducer;
    const { categories } = this.props.categoryReducer;
    return (
      <Form>
        <Row>
          <Col lg={8} sm={12}>
            <Card className="main-card shadow">
              <PostEditor
                text={this.state.body}
                onTextChange={this.onBodyChange}
                postId={this.props.match.params.id}
              />
            </Card>
          </Col>
          <Col lg={4} sm={12}>
            <Card className="my-4 mb-7 shadow">
              <Affix className="post_save_bar" offset={0}>
                <CardHeader>
                  <Row>
                    <Col>
                      <Button
                        className="w-100"
                        color="white"
                        onClick={this.goback.bind(this)}
                        disabled={updatingPost}
                      >
                        返回
                      </Button>
                    </Col>
                    <Col>
                      <a
                        className="btn btn-white w-100"
                        target="_brank"
                        href={PREVIEW_BASE_URL + this.state.id * 2}
                      >
                        预览
                      </a>
                    </Col>
                  </Row>
                  <Button
                    className="w-100 mt-3"
                    color="info"
                    onClick={this.saveAndExit}
                    disabled={updatingPost}
                  >
                    {updatingPost ? "保存中..." : "保存并退出"}
                  </Button>
                </CardHeader>
              </Affix>
              <CardBody>
                <FormGroup>
                  <div>
                    <CustomInput
                      type="radio"
                      id="inputActive"
                      name="active"
                      label="发布"
                      inline
                      checked={this.state.active}
                      onChange={() => this.onActiveChange(true)}
                    />
                    <CustomInput
                      type="radio"
                      id="inputActive2"
                      name="active"
                      label="草稿"
                      inline
                      checked={!this.state.active}
                      onChange={() => this.onActiveChange(false)}
                    />
                  </div>
                  <span className="d-block text-danger">{errors.active}</span>
                </FormGroup>
                <FormGroup>
                  <Label for="inputTitle">标题</Label>
                  <Input
                    type="text"
                    name="title"
                    id="inputTitle"
                    placeholder="输入标题..."
                    value={this.state.title}
                    onChange={this.onInputChange}
                    invalid={!errors.title === false}
                  />
                  <FormFeedback invalid={errors.title}>
                    {errors.title}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputAuthor">作者</Label>
                  <Input
                    type="text"
                    name="author"
                    id="inputAuthor"
                    placeholder="输入作者或来源..."
                    value={this.state.author}
                    onChange={this.onInputChange}
                    invalid={!errors.author === false}
                  />
                  <FormFeedback invalid={errors.author}>
                    {errors.author}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>封面</Label>
                  <CoverEditor
                    coverImg={this.state.coverImg}
                    onCoverChange={this.onCoverChange}
                    postId={this.props.match.params.id}
                  />
                  <span className="d-block text-warning f-12">
                    {errors.coverImg}
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label for="inputYoutube">Youtube视频</Label>
                  <Input
                    type="text"
                    name="youtube_video"
                    id="inputYoutube"
                    placeholder="输入Youtube视频ID..."
                    value={this.state.youtube_video}
                    onChange={this.onInputChange}
                    invalid={!errors.youtube_video === false}
                  />
                  <FormFeedback invalid={errors.youtube_video}>
                    {errors.youtube_video}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="inputPreface">简述</Label>
                  <Input
                    type="textarea"
                    name="preface"
                    id="inputPreface"
                    placeholder="输入简洁描述..."
                    value={this.state.preface || ""}
                    onChange={this.onInputChange}
                    invalid={!errors.preface === false}
                  />
                  <FormFeedback invalid={errors.preface}>
                    {errors.preface}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="inputPostedAt">发布时间</Label>
                  <Input
                    type="datetime"
                    name="posted_at"
                    id="inputPostedAt"
                    placeholder="输入发布时间..."
                    value={this.state.posted_at}
                    onChange={this.onInputChange}
                    invalid={!errors.posted_at === false}
                  />
                  <FormFeedback invalid={errors.posted_at}>
                    {errors.posted_at}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="inputDisplay">分类</Label>
                  <div className="d-flex flex-wrap">
                    {categories ? (
                      <TreeView
                        categories={categories}
                        category_ids={this.state.category_ids}
                        onCategoryIdsChange={this.onCategoryIdsChange}
                      />
                    ) : null}
                  </div>
                  <span className="d-block text-warning f-12">
                    {errors.category_ids}
                  </span>
                </FormGroup>

                <ImageDropzone
                  postId={this.props.match.params.id}
                  files={this.props.postReducer.uploadImages}
                  handleUpload={this.batchUpload}
                  onChange={this.onInputChange}
                />
              </CardBody>
              <CardFooter>
                <h5>已阅：{this.state.views ? this.state.views : "N/A"}</h5>
                <h5>
                  编辑：{this.state.editor ? this.state.editor.name : "N/A"}
                </h5>
                <h5>
                  上次更新：
                  {this.state.updated_at ? (
                    <Moment
                      parse="YYYY-MM-DD HH:mm:ss"
                      format="MM/DD/YYYY hh:mma"
                    >
                      >{this.state.updated_at}
                    </Moment>
                  ) : null}
                </h5>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default PostEdit;
