import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col
} from "reactstrap";
import SingleImageDropzone from "../common/SingleImageDropzone";

class AdModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Create",
      id: null,
      company_name: "",
      imageServer: "",
      imagePath: "",
      file: null,
      active: 0,
      display_section_id: null,
      link_to: ""
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onDisplaySectionIdChange = this.onDisplaySectionIdChange.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleCreateAd = this.handleCreateAd.bind(this);
    this.handleUpdateAd = this.handleUpdateAd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (!this.props.adReducer.display_sections) {
      this.props.getDisplaySections();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.adReducer.selectedAd !== this.props.adReducer.selectedAd) {
      const {
        id,
        company_name,
        imageServer,
        imagePath,
        active,
        display_section_id,
        link_to
      } = nextProps.adReducer.selectedAd;
      this.setState({
        mode: id ? "Edit" : "Create",
        id: id ? id : null,
        company_name: company_name ? company_name : "",
        imageServer: imageServer ? imageServer : "",
        imagePath: imagePath ? imagePath : "",
        active: active ? active : false,
        display_section_id: display_section_id ? display_section_id : null,
        file: null,
        link_to: link_to
      });
    }
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onCheckboxChange(e) {
    this.setState({
      [e.target.name]: e.target.checked
    });
  }

  onDisplaySectionIdChange(section_id) {
    this.setState({
      display_section_id: section_id
    });
  }

  onChangeFile(file) {
    this.setState({
      file
    });
  }

  handleCreateAd() {
    const {
      company_name,
      file,
      active,
      display_section_id,
      link_to
    } = this.state;
    this.props.createAd({
      company_name,
      file,
      display_section_id,
      active,
      link_to
    });
  }

  handleUpdateAd() {
    const {
      id,
      company_name,
      file,
      active,
      display_section_id,
      link_to
    } = this.state;
    this.props.updateAd({
      id,
      company_name,
      file,
      display_section_id,
      active,
      link_to
    });
  }

  handleDelete() {
    const { id } = this.state;
    this.props.deleteAd(id);
  }

  render() {
    const {
      openAdModal,
      errors,
      errorMsg,
      successMsg,
      display_sections
    } = this.props.adReducer;

    const closeBtn = (
      <button className="close" onClick={this.props.toggleAdModal}>
        <i className="fa fa-times" /> 关闭
      </button>
    );

    return (
      <Modal
        isOpen={openAdModal}
        toggle={this.props.toggleAdModal}
        centered={true}
        contentClassName="bg-lightest"
        size="sm"
      >
        <ModalHeader close={closeBtn}>
          {this.state.mode === "Edit" ? "编辑广告" : "新广告"}
        </ModalHeader>
        <ModalBody>
          <span className="d-block text-warning text-center">{errorMsg}</span>
          <span className="d-block text-success text-center">{successMsg}</span>
          <Form>
            <FormGroup>
              <SingleImageDropzone
                src={this.state.imageServer + this.state.imagePath}
                handleUpload={this.onChangeFile}
              />
              <span className="d-block text-warning f-12">{errors.file}</span>
            </FormGroup>
            <FormGroup>
              <Label for="inputDisplay">广告位置</Label>
              <div className="d-flex">
                {display_sections
                  ? display_sections.map(section => (
                      <Button
                        key={`section_id-${section.id}`}
                        size="sm"
                        color={
                          this.state.display_section_id === section.id
                            ? "default"
                            : "white"
                        }
                        onClick={() =>
                          this.onDisplaySectionIdChange(section.id)
                        }
                      >
                        {section.name}
                      </Button>
                    ))
                  : null}
              </div>
              <span className="d-block text-warning f-12">
                {errors.display_section_id}
              </span>
            </FormGroup>
            <FormGroup>
              <Label for="inputName">公司名称</Label>
              <Input
                type="text"
                name="company_name"
                id="inputName"
                value={this.state.company_name || ""}
                onChange={this.onInputChange}
                invalid={!errors.company_name === false}
                autoComplete="off"
                placeholder="例：恒丰银行"
              />
              <FormFeedback invalid={errors.company_name}>
                {errors.company_name}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="inputLink">跳转链接</Label>
              <Input
                type="text"
                name="link_to"
                id="inputLink"
                value={this.state.link_to || ""}
                onChange={this.onInputChange}
                invalid={!errors.link_to === false}
                autoComplete="off"
                placeholder="例：https://www.afnb.com"
              />
              <FormFeedback invalid={errors.link_to}>
                {errors.link_to}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <div className="custom-control custom-checkbox">
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  name="active"
                  checked={this.state.active}
                  onChange={this.onCheckboxChange}
                  invalid={!errors.active === false}
                />
                <Label className="custom-control-label" for="customCheck1">
                  <span className="badge badge-dot">
                    <i
                      className={
                        this.state.active ? "bg-success" : "bg-warning"
                      }
                    />
                    <span className="status" style={{ top: 0 }}>
                      {this.state.active ? "发布" : "暂不发布"}
                    </span>
                  </span>
                </Label>
              </div>
              <span className="d-block text-warning f-12">{errors.active}</span>
            </FormGroup>
            {this.state.mode === "Edit" ? (
              <Row>
                <Col>
                  <Button
                    color="danger"
                    className="w-100"
                    type="button"
                    onClick={() =>
                      this.props.toggleDeleteAdModal(
                        this.props.adReducer.selectedAd
                      )
                    }
                  >
                    删除
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="info"
                    className="w-100"
                    type="button"
                    onClick={this.handleUpdateAd}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Button
                    color="info"
                    className="w-100"
                    type="button"
                    onClick={this.handleCreateAd}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default AdModal;
