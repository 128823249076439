import React, { Component } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import TreeView from "./TreeView";
import PropTypes from "prop-types";

class CategoriesCollapse extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  onCategoryIdsChange(category_ids) {
    this.props.onCategoryIdsChange(category_ids);
  }

  render() {
    const { categories, category_ids } = this.props;
    return (
      <div className="collapse-dropdown">
        <Button
          color="white"
          className="btn-block"
          onClick={this.toggle}
          style={{ marginBottom: "1rem" }}
        >
          选择分类
        </Button>
        <Collapse
          isOpen={this.state.collapse}
          className="collapse-dropdown-menu"
        >
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                {categories ? (
                  <TreeView
                    categories={categories}
                    category_ids={category_ids}
                    onCategoryIdsChange={this.onCategoryIdsChange.bind(this)}
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}

export default CategoriesCollapse;

CategoriesCollapse.propTypes = {
  categories: PropTypes.array.isRequired,
  category_ids: PropTypes.array.isRequired,
  onCategoryIdsChange: PropTypes.func.isRequired
};
