import React from "react";
import { Card, Row, Col, CardHeader, CardFooter, Button } from "reactstrap";
import Pagination from "../common/Pagination";
import UserModal from "./UserModal";
export default class UserList extends React.Component {
  render() {
    const { users } = this.props;

    const paginationDiv = users ? (
      <Pagination
        currentPage={users.current_page}
        totalPages={users.last_page}
        pageNeighbours={1}
        goToPage={page => this.props.getUsers(page)}
      />
    ) : null;

    return (
      <div className="container-fluid mt-2">
        <UserModal {...this.props} />
        <Card className="shadow border-0 border-l main-card">
          <CardHeader className="border-0 border-top-l">
            <Row>
              <Col md={4} sm={6}>
                <h3 className="mb-0">员工管理</h3>
              </Col>
              <Col md={8} sm={6} className="text-right">
                <Button color="info" onClick={this.props.toggleUserModal}>
                  加入新员工
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr>
                  <th>员工</th>
                  <th>邮箱</th>
                  <th>权限</th>
                  <th>状态</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {users.data
                  ? users.data.map(user => (
                      <tr key={`user-${user.id}`}>
                        <td>
                          {user.first_name + " " + user.last_name}({user.name})
                        </td>
                        <td>{user.email}</td>
                        <td>{user.role_id === 1 ? "管理员" : "编辑"}</td>
                        <td>{user.active ? "在职" : "离职"}</td>
                        <td>
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => this.props.toggleUserModal(user)}
                          >
                            编辑
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <CardFooter className="border-bottom-l">{paginationDiv}</CardFooter>
        </Card>
      </div>
    );
  }
}
