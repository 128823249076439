import {
  AUTH_LOG_IN_REQUEST,
  AUTH_LOG_IN_SUCCESS,
  AUTH_LOG_IN_FAIL,
  AUTH_GET_PROFILE_REQUEST,
  AUTH_GET_PROFILE_SUCCESS,
  AUTH_GET_PROFILE_FAIL,
  AUTH_LOG_OUT,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAIL,
  AUTH_UPDATE_PROFILE_REQUEST,
  AUTH_UPDATE_PROFILE_SUCCESS,
  AUTH_UPDATE_PROFILE_FAIL,
  AUTH_CHANGE_PASSWORD_REQUEST,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAIL
} from "../actions/authAction";

const initialState = {
  loading: false,
  profile: null,
  errorMsg: "",
  successMsg: "",
  errors: {},
  updatingProfile: false
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOG_IN_REQUEST:
      return {
        ...state,
        loading: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case AUTH_LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: action.msg,
        errorMsg: ""
      };
    case AUTH_LOG_IN_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case AUTH_GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case AUTH_GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.profile,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case AUTH_GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case AUTH_LOG_OUT:
      return {
        ...state,
        profile: null
      };
    case AUTH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case AUTH_RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case AUTH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case AUTH_FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case AUTH_UPDATE_PROFILE_REQUEST:
      return {
        updatingProfile: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case AUTH_UPDATE_PROFILE_SUCCESS:
      return {
        updatingProfile: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {},
        profile: action.user
      };
    case AUTH_UPDATE_PROFILE_FAIL:
      return {
        updatingProfile: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case AUTH_CHANGE_PASSWORD_REQUEST:
      return {
        updatingProfile: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case AUTH_CHANGE_PASSWORD_SUCCESS:
      return {
        updatingProfile: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case AUTH_CHANGE_PASSWORD_FAIL:
      return {
        updatingProfile: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default auth;
