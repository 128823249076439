import React from "react";
import { Row, Col, Button } from "reactstrap";
import "./AdItem.scss";

export default class AdItem extends React.Component {
  render() {
    const { ad } = this.props;
    return (
      <li className="list-group-item px-0 ad-item" key={`ads-${ad.id}`}>
        <Row className="align-items-center m-0">
          <Col className="col-auto">
            <div
              className="list-ad-image mx-2"
              style={{
                backgroundImage: `url(${ad.imageServer + ad.imagePath})`
              }}
            />
          </Col>
          <Col className="pl-0 ad-item-content">
            <h4>{ad.company_name}</h4>
            {ad.link_to}
            <div className="d-flex justify-content-between">
              <span className="badge badge-dot">
                <i className={ad.active ? "bg-success" : "bg-warning"} />
                <span className="status" style={{ top: 0 }}>
                  {ad.active ? "发布" : "暂不发布"}
                </span>
              </span>
              <Button
                size="sm"
                color="info"
                onClick={() => this.props.toggleAdModal(ad)}
              >
                编辑
              </Button>
            </div>
          </Col>
        </Row>
      </li>
    );
  }
}
