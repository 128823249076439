import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  CustomInput
} from "reactstrap";

class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Create",
      id: 0,
      name: "",
      first_name: "",
      last_name: "",
      email: "",
      active: 1,
      role_id: null
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onActiveChange = this.onActiveChange.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userReducer.selectedUser !== this.props.userReducer.selectedUser
    ) {
      const {
        id,
        name,
        first_name,
        last_name,
        email,
        active,
        role_id
      } = nextProps.userReducer.selectedUser;
      this.setState({
        mode: id ? "Edit" : "Create",
        id: id ? id : 0,
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        name: name ? name : "",
        email: email ? email : "",
        role_id: role_id ? role_id : 2,
        active: active ? active : false
      });
    }
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onActiveChange(active) {
    this.setState({
      active: active
    });
  }

  onRoleChange(role_id) {
    this.setState({
      role_id: role_id
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      mode,
      id,
      name,
      first_name,
      last_name,
      email,
      active,
      role_id
    } = this.state;
    if (mode === "Edit") {
      this.props.updateUser({
        id,
        name,
        first_name,
        last_name,
        email,
        active,
        role_id
      });
    } else {
      this.props.createUser({
        name,
        first_name,
        last_name,
        email,
        active,
        role_id
      });
    }
  }

  render() {
    const {
      errors,
      errorMsg,
      successMsg,
      openUserModal,
      updatingUser
    } = this.props.userReducer;
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggleUserModal({})}>
        <i className="fa fa-times" /> 关闭
      </button>
    );
    return (
      <Modal
        isOpen={openUserModal}
        toggle={() => this.props.toggleUserModal({})}
        centered={true}
        contentClassName="bg-lightest"
        size="sm"
      >
        <ModalHeader close={closeBtn}>
          {this.state.mode === "Edit" ? "编辑用户" : "新用户"}
        </ModalHeader>
        <ModalBody>
          <span className="d-block text-warning text-center">{errorMsg}</span>
          <span className="d-block text-success text-center">{successMsg}</span>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup>
              <Label for="inputEmail">邮箱</Label>
              <Input
                type="email"
                name="email"
                id="inputEmail"
                placeholder="carol@gmail.com"
                className="input-group-alternative"
                value={this.state.email}
                onChange={this.onInputChange}
                invalid={!errors.email === false}
              />
              <FormFeedback invalid={errors.email}>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="inputFirstName">名字</Label>
              <Input
                type="text"
                name="first_name"
                id="inputFirstName"
                placeholder="e.g. Carol"
                className="input-group-alternative"
                value={this.state.first_name}
                onChange={this.onInputChange}
                invalid={!errors.first_name === false}
              />
              <FormFeedback invalid={errors.first_name}>
                {errors.first_name}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="inputLastName">姓氏</Label>
              <Input
                type="text"
                name="last_name"
                id="inputLastName"
                placeholder="e.g. Chen"
                className="input-group-alternative"
                value={this.state.last_name}
                onChange={this.onInputChange}
                invalid={!errors.last_name === false}
              />
              <FormFeedback invalid={errors.last_name}>
                {errors.last_name}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="inputName">笔名</Label>
              <Input
                type="text"
                name="name"
                id="inputName"
                placeholder="输入作者笔名"
                className="input-group-alternative"
                value={this.state.name}
                onChange={this.onInputChange}
                invalid={!errors.name === false}
              />
              <FormFeedback invalid={errors.name}>{errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <div>
                <CustomInput
                  type="radio"
                  id="inputRole"
                  name="role_id"
                  label="管理员"
                  inline
                  checked={this.state.role_id === 1}
                  onChange={() => this.onRoleChange(1)}
                />
                <CustomInput
                  type="radio"
                  id="inputRole2"
                  name="role_id"
                  label="编辑"
                  inline
                  checked={this.state.role_id === 2}
                  onChange={() => this.onRoleChange(2)}
                />
              </div>
              <span className="d-block text-danger">{errors.name}</span>
            </FormGroup>

            <FormGroup>
              <div>
                <CustomInput
                  type="radio"
                  id="inputActive"
                  name="active"
                  label="在职"
                  inline
                  checked={this.state.active}
                  onChange={() => this.onActiveChange(true)}
                />
                <CustomInput
                  type="radio"
                  id="inputActive2"
                  name="active"
                  label="离职"
                  inline
                  checked={!this.state.active}
                  onChange={() => this.onActiveChange(false)}
                />
              </div>
              <span className="d-block text-danger">{errors.active}</span>
            </FormGroup>
            <Row>
              <Col>
                <Button
                  color="white"
                  className="btn-block"
                  type="button"
                  onClick={() => this.props.toggleUserModal({})}
                >
                  关闭
                </Button>
              </Col>
              <Col>
                <Button
                  color="info"
                  className="btn-block"
                  type="submit"
                  disabled={updatingUser}
                >
                  {this.state.mode === "Edit" ? "更新" : "创建"}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default UserModal;
