import {
  USER_GET_ALL_REQUEST,
  USER_GET_ALL_SUCCESS,
  USER_GET_ALL_FAIL,
  USER_TOGGLE_USER_MODAL,
  USER_CREATE_USER_REQUEST,
  USER_CREATE_USER_SUCCESS,
  USER_CREATE_USER_FAIL,
  USER_UPDATE_USER_REQUEST,
  USER_UPDATE_USER_SUCCESS,
  USER_UPDATE_USER_FAIL
} from "../actions/usersAction";
const initialState = {
  users: null,
  userOptions: [],
  loading: false,
  successMsg: "",
  errorMsg: "",
  errors: {},
  selectedUser: {},
  openUserModal: false,
  updatingUser: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USER_GET_ALL_SUCCESS:
      return {
        ...state,
        users: action.users,
        userOptions: action.userOptions,
        loading: false
      };
    case USER_GET_ALL_FAIL:
      return {
        ...state,
        users: [],
        loading: false
      };
    case USER_TOGGLE_USER_MODAL:
      return {
        ...state,
        selectedUser: action.selectedUser,
        openUserModal: !state.openUserModal,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case USER_CREATE_USER_REQUEST:
      return {
        ...state,
        updatingUser: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case USER_CREATE_USER_SUCCESS:
      return {
        ...state,
        openUserModal: false,
        updatingUser: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case USER_CREATE_USER_FAIL:
      return {
        ...state,
        updatingUser: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case USER_UPDATE_USER_REQUEST:
      return {
        ...state,
        updatingUser: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case USER_UPDATE_USER_SUCCESS:
      return {
        ...state,
        openUserModal: false,
        updatingUser: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case USER_UPDATE_USER_FAIL:
      return {
        ...state,
        updatingUser: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default user;
