import React from "react";
import { bindActionCreators } from "redux";
import Sidebar from "../../components/common/Sidebar";
import { connect } from "react-redux";
import { getProfile, logout } from "../../lib/actions/authAction";
import GlobalToast from "../../components/common/GlobalToast";
import { push } from "connected-react-router";
import "./index.scss";

class Layout extends React.Component {
  componentDidMount() {
    this.props.getProfile();
  }

  render() {
    return (
      <div className="layout">
        <Sidebar {...this.props} auth={this.props.authReducer} />
        <div className="main-content" auth={this.props.authReducer}>
          <GlobalToast />
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile: () => getProfile(),
      logout: () => logout(),
      goNewPage: path => push(path)
    },
    dispatch
  );
const mapStateToProps = state => {
  const { authReducer } = state;
  return {
    authReducer
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
