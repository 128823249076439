// import React from "react";
// import ReactDOM from "react-dom";
// import store, { history } from "./lib/store";
// import Root from "./containers/Root";
// import "./index.css";
// import * as serviceWorker from "./serviceWorker";

// ReactDOM.render(
//   <Root store={store} history={history} />,
//   document.getElementById("root")
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./lib/store";
import App from "./containers/App";

import "./index.css";

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
);
