import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../layout";
import {
  getUsers,
  toggleUserModal,
  createUser,
  updateUser
} from "../../lib/actions/usersAction";
import UserList from "../../components/users/UserList";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount() {
    this.getUsers({ page: 1, name: "" });
  }

  getUsers(page) {
    this.props.getUsers({ page, name: "" });
  }

  render() {
    return (
      <Layout>
        {this.props.userReducer.users ? (
          <div>
            <UserList
              {...this.props}
              users={this.props.userReducer.users}
              getUsers={this.getUsers}
            />
          </div>
        ) : null}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { userReducer } = state;
  return {
    userReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers: ({ page, name }) => getUsers({ page, name }),
      toggleUserModal: user => toggleUserModal(user),
      createUser: params => createUser(params),
      updateUser: params => updateUser(params)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
