import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import simpleReducer from "./simpleReducer";
import userReducer from "./userReducer";
import postReducer from "./postReducer";
import authReducer from "./authReducer";
import adReducer from "./adReducer";
import categoryReducer from "./categoryReducer";

export default history =>
  combineReducers({
    simpleReducer,
    userReducer,
    postReducer,
    authReducer,
    adReducer,
    categoryReducer,
    router: connectRouter(history)
  });
