import React from "react";
import Layout from "../layout";
class Error404 extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Layout>
        <div class="container-fluid">
          <div className="card main-card d-flex align-items-center justify-content-center">
            <h1 className="text-warning">
              Oops~ <br />
              页面不存在!
            </h1>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Error404;
