import Api from "../api.request";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

export const POST_GET_POSTS_REQUEST = "POST_GET_POSTS_REQUEST";
export const POST_GET_POSTS_SUCCESS = "POST_GET_POSTS_SUCCESS";
export const POST_GET_POSTS_FAIL = "POST_GET_POSTS_FAIL";
export const getPosts = ({ page, category_ids, user_id }) => dispatch => {
  dispatch({ type: POST_GET_POSTS_REQUEST });
  const params = {
    page: page ? page : 1,
    category_ids: category_ids ? category_ids : [],
    user_id
  };
  Api.request({
    url: "posts",
    params,
    method: "get"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: POST_GET_POSTS_SUCCESS, users: data.data });
    } else {
      dispatch({ type: POST_GET_POSTS_FAIL, errorMsg: data.message });
    }
  });
};

export const POST_GET_POST_REQUEST = "POST_GET_POST_REQUEST";
export const POST_GET_POST_SUCCESS = "POST_GET_POST_SUCCESS";
export const POST_GET_POST_FAIL = "POST_GET_POST_FAIL";
export const getPost = post_id => dispatch => {
  dispatch({ type: POST_GET_POST_REQUEST });
  Api.request({
    url: "post/" + post_id,
    params: {},
    method: "get"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: POST_GET_POST_SUCCESS,
        post: data.data,
        msg: data.message
      });
    } else {
      dispatch({
        type: POST_GET_POST_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const POST_CREATE_POST_FAIL = "POST_GET_POST_FAIL";
export const createPost = () => dispatch => {
  Api.request({
    url: "create-post",
    method: "post"
  }).then(({ data }) => {
    if (data.success) {
      dispatch(push("/posts/edit/" + data.data.id));
    } else {
      dispatch({
        type: POST_GET_POSTS_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
    }
  });
};

export const POST_UPDATE_POST_REQUEST = "POST_UPDATE_POST_REQUEST";
export const POST_UPDATE_POST_SUCCESS = "POST_UPDATE_POST_SUCCESS";
export const POST_UPDATE_POST_FAIL = "POST_UPDATE_POST_FAIL";
export const updatePost = params => dispatch => {
  dispatch({ type: POST_UPDATE_POST_REQUEST });
  const goback = params.goback ? true : false;
  Api.request({
    url: "update-post",
    data: params,
    method: "post"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: POST_UPDATE_POST_SUCCESS,
        msg: data.message,
        post: data.data
      });
      if (goback) {
        dispatch(push("/"));
      }
      // toast.success(data.message, {
      //   position: toast.POSITION.BOTTOM_LEFT
      // });
    } else {
      dispatch({
        type: POST_UPDATE_POST_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  });
};

export const publishPost = post_id => dispatch => {
  toast.success("Published", {
    position: toast.POSITION.BOTTOM_LEFT
  });
};

export const POST_DELETE_POST_REQUEST = "POST_DELETE_POST_REQUEST";
export const POST_DELETE_POST_SUCCESS = "POST_DELETE_POST_SUCCESS";
export const POST_DELETE_POST_FAIL = "POST_DELETE_POST_FAIL";
export const deletePost = post_id => dispatch => {
  dispatch({ type: POST_DELETE_POST_REQUEST });
  Api.request({
    url: "delete-post",
    data: { post_id },
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: POST_DELETE_POST_SUCCESS, msg: data.message });
      dispatch(getPosts({ page: 1 }));
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      dispatch({ type: POST_DELETE_POST_FAIL, msg: data.message });
      toast.warn(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  });
};

export const POST_BATCH_UPLOAD_IMAGES = "POST_BATCH_UPLOAD_IMAGES";
export const batchUploadImages = ({ post_id, files }) => dispatch => {
  dispatch({ type: POST_BATCH_UPLOAD_IMAGES, images: files, post_id });
  for (var i = 0; i < files.length; i++) {
    const file = files[i];
    dispatch(uploadPostImage({ post_id, file }));
  }
};

export const POST_UPLOAD_IMAGE_STATUS = "POST_UPLOAD_IMAGE_STATUS";
export const uploadPostImage = ({ post_id, file }) => dispatch => {
  let formData = new FormData();
  formData.append("post_id", post_id);
  formData.append("file", file);
  Api.request({
    url: "upload-post-image",
    data: formData,
    method: "post",
    onUploadProgress: function(progressEvent) {
      if (progressEvent.lengthComputable) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted < 100) {
          dispatch({
            type: POST_UPLOAD_IMAGE_STATUS,
            file,
            percentCompleted,
            success: false
          });
        }
      }
    }
  }).then(({ data }) => {
    if (data.success) {
      dispatch({
        type: POST_UPLOAD_IMAGE_STATUS,
        file,
        percentCompleted: 100,
        success: true
      });
    } else {
      dispatch({
        type: POST_UPLOAD_IMAGE_STATUS,
        file,
        percentCompleted: 100,
        success: false
      });
    }
  });
};

export const POST_TOGGLE_DELETE_POST_MODAL = "POST_TOGGLE_DELETE_POST_MODAL";
export const toggleDeletePostModal = post => dispatch => {
  dispatch({
    type: POST_TOGGLE_DELETE_POST_MODAL,
    selectedPost: post ? post : {}
  });
};

export const POST_CLEAR_UPLOAD_IMAGES = "POST_CLEAR_UPLOAD_IMAGES";
export const clearUploadImage = () => dispatch => {
  dispatch({
    type: POST_CLEAR_UPLOAD_IMAGES
  });
};
