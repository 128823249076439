import React from "react";
import GlobalToast from "../../components/common/GlobalToast";

const Layout = props => (
  <div className="layout bg-default">
    <GlobalToast />
    <div className="container">{props.children}</div>
  </div>
);

export default Layout;
