import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { updateProfile, changePassword } from "../../lib/actions/authAction";
import Layout from "../layout";
import UpdateProfileForm from "../../components/profile/UpdateForm";

class Profile extends React.Component {
  render() {
    return (
      <Layout>
        <div className="container-fluid">
          <UpdateProfileForm {...this.props} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { authReducer } = state;
  return {
    authReducer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goNewPage: path => push(path),
      updateProfile: params => updateProfile(params),
      changePassword: params => changePassword(params)
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
