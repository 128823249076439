// Note that Froala Editor has to be required separately
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditorImg from "react-froala-wysiwyg/FroalaEditorImg";
import React from "react";
import defaultImage from "../../assets/img/default.png";
import { API_BASE_URL } from "../../lib/utils/config";
import Cookies from "js-cookie";
import "./CoverEditor.scss";

// Render Froala Editor component.
export default class EditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.baseURL = API_BASE_URL;
    this.config = {
      reactIgnoreAttrs: ["tmpattr"],
      key: "DA4B1F3E3H-9C1A8B3B5A8B1F2E5A4F4nupxssqxF2no==",
      language: "zh_cn",
      imageDefaultWidth: 0,
      imageResize: false,
      imageMaxSize: 1024 * 1024 * 50,
      imageEditButtons: ["imageReplace"],
      requestHeaders: {
        Authorization: "Bearer " + Cookies.get("access_token")
      },
      // upload images
      imageUploadParams: { post_id: props.postId, is_froala: true },
      imageUploadURL: this.baseURL + "/upload-post-image",
      //load images
      imageManagerLoadMethod: "POST",
      imageManagerLoadParams: { id: props.postId },
      imageManagerLoadURL: this.baseURL + "/get-post-images",
      // delete images
      imageManagerDeleteURL: this.baseURL + "/delete-post-image",
      imageManagerDeleteMethod: "POST"
    };

    this.handleModelChange = this.handleModelChange.bind(this);

    this.state = {
      content: {
        src: defaultImage,
        id: "froalaEditor",
        tmpattr: "This attribute will be ignored on change."
      }
    };
  }

  componentDidMount() {
    var content = { ...this.state.content, src: this.props.coverImg };
    this.setState({
      content
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coverImg !== this.props.coverImg) {
      var content = {
        ...this.state.content,
        src: nextProps.coverImg ? nextProps.coverImg : defaultImage
      };
      this.setState({
        content
      });
    }
  }

  handleModelChange(model) {
    this.setState({ content: model });
    this.props.onCoverChange(model.src);
  }

  render() {
    return (
      <div className="w-100">
        <div className="FroalaEditorImgWrapper">
          <FroalaEditorImg
            config={this.config}
            model={this.state.content}
            onModelChange={this.handleModelChange}
          />
          {/* <div>{JSON.stringify(this.state.content)}</div> */}
        </div>
      </div>
    );
  }
}
