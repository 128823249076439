import React from "react";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "font-awesome/css/font-awesome.css";

import FroalaEditor from "react-froala-wysiwyg";
import { API_BASE_URL } from "../../lib/utils/config";
import Cookies from "js-cookie";

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from "react-froala-wysiwyg/FroalaEditorImg";
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

class PostEditor extends React.Component {
  constructor(props) {
    super(props);

    this.baseURL = API_BASE_URL;
    this.menu = [
      "undo",
      "redo",
      "print",
      "|",
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "|",
      "specialCharacters",
      "color",
      "emoticons",
      "-",
      "paragraphFormat",
      "align",
      "formatOL",
      "formatUL",
      "quote",
      "insertHR",
      "-",
      "insertLink",
      "insertImage",
      "insertVideo",
      "insertFile",
      "insertTable",
      "html"
    ];
    this.config = {
      key: "DA4B1F3E3H-9C1A8B3B5A8B1F2E5A4F4nupxssqxF2no==",
      language: "zh_cn",
      placeholderText: "在这里编辑正文",
      charCounterCount: true,
      toolbarInline: false,
      toolbarButtons: this.menu,
      toolbarButtonsMD: this.menu,
      toolbarButtonsSM: this.menu,
      toolbarButtonsXS: this.menu,
      imageDefaultAlign: "center",
      imageDefaultWidth: 0,
      imageDefaultDisplay: "block",
      pastePlain: true,
      fontSize: ['8', '10', '12', '14', '16', '18', '30', '60', '96'],
      colorsText: [
        "#99ff33",
        "#1ABC9C",
        "#54ACD2",
        "#2C82C9",
        "#9365B8",
        "#475577",
        "#CCCCCC",
        "#41A85F",
        "#00dd00",
        "#9900FF",
        "#0000DD",
        "#553982",
        "#28324E",
        "#000000",
        "#F7DA64",
        "#FBA026",
        "#EB6B56",
        "#dd0000",
        "#A38F84",
        "#EFEFEF",
        "#FFFFFF",
        "#ffff00",
        "#F37934",
        "#cc0000",
        "#ff0000",
        "#7C706B",
        "#D1D5D8",
        "REMOVE"
      ],
      colorsBackground: ["#61BD6D", "#1ABC9C", "#54ACD2", "REMOVE"],
      fontFamily: {
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman"
      },
      videoResponsive: true,
      videoUpload: false,
      imageStyles: {
        w100: "w-100"
      },
      imageResize: true,
      imageMaxSize: 1024 * 1024 * 50,
      requestHeaders: {
        Authorization: "Bearer " + Cookies.get("access_token")
      },
      // upload images
      imageUploadParams: { post_id: props.postId, is_froala: true },
      imageUploadURL: this.baseURL + "/upload-post-image",
      // upload fiile
      fileUploadParams: { post_id: props.postId, is_froala: true },
      fileUploadURL: this.baseURL + "/upload-post-file",
      //load images
      imageManagerLoadMethod: "POST",
      imageManagerLoadParams: { id: props.postId },
      imageManagerLoadURL: this.baseURL + "/get-post-images",
      // delete images
      imageManagerDeleteURL: this.baseURL + "/delete-post-image",
      imageManagerDeleteMethod: "POST",
      events: {
        "froalaEditor.initialized": function() {
          // console.log("initialized");
        },
        "froalaEditor.image.uploaded": function(e, editor, response) {
          // Do something here.
          // console.log(e);
        }
      }
    };
  }

  render() {
    return (
      <div>
        <FroalaEditor
          config={this.config}
          model={this.props.text}
          onModelChange={this.props.onTextChange}
        />
      </div>
    );
  }
}

export default PostEditor;
