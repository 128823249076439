import React from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardFooter,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Pagination from "../common/Pagination";
import CategoriesCollapse from "../categories/CategoriesCollapse";
import UsersDropdown from "../users/UsersDropdown";
import Moment from "react-moment";
import DeletePostModal from "./DeletePostModal";

export default class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.renderAdminDiv = this.renderAdminDiv.bind(this);
  }
  onCategoryIdsChange(category_ids) {
    this.props.onCategoryIdsChange(category_ids);
  }

  onUserSelectChange(user) {
    if (user) {
      this.props.onUserChange(user.value);
    }
  }

  onUserSelectInputChange(value) {
    this.props.getUsers({ page: 1, name: value });
  }

  renderAdminDiv(comp) {
    let usersDropdown = null;
    const { profile } = this.props.authReducer;
    const { userOptions } = this.props.userReducer;
    if (profile) {
      if (profile.role_id === 1 && userOptions) {
        usersDropdown = (
          <UsersDropdown
            userOptions={userOptions}
            onSelectChange={this.onUserSelectChange.bind(this)}
            onInputChange={this.onUserSelectInputChange.bind(this)}
          />
        );
      }
    }
    switch (comp) {
      case "usersDropdown":
        return usersDropdown;
      default:
        return null;
    }
  }

  render() {
    const { category_ids } = this.props;
    const { posts } = this.props.postReducer;

    const { categories } = this.props.categoryReducer;

    // const { userOptions } = this.props.userReducer;

    const paginationDiv = posts ? (
      <Pagination
        currentPage={posts.current_page}
        totalPages={posts.last_page}
        pageNeighbours={1}
        goToPage={page => this.props.goToPage(page)}
      />
    ) : null;

    return (
      <div className="container-fluid mt-2">
        <DeletePostModal {...this.props} />
        <Card className="shadow border-0 border-l main-card">
          <CardHeader className="border-0 border-top-l">
            <Row>
              <Col md={4} sm={6}>
                {categories ? (
                  <CategoriesCollapse
                    categories={categories}
                    category_ids={category_ids}
                    onCategoryIdsChange={this.onCategoryIdsChange.bind(this)}
                  />
                ) : null}
              </Col>
              <Col md={4} sm={6}>
                {this.renderAdminDiv("usersDropdown")}
              </Col>
              <Col md={4} sm={6} className="text-right">
                <Button color="info" onClick={this.props.createPost}>
                  写文章
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr>
                  <th>状态</th>
                  <th>标题</th>
                  <th>编辑</th>
                  <th>发布时间</th>
                  <th className="text-right">共{posts.total}</th>
                </tr>
              </thead>
              <tbody>
                {posts.data
                  ? posts.data.map(post => (
                      <tr key={`posts-${post.id}`}>
                        <td>
                          <span className="badge badge-dot mr-4">
                            <i
                              className={
                                post.active ? "bg-success" : "bg-warning"
                              }
                            />
                            <span className="status">
                              {post.active ? "发布" : "草稿"}
                            </span>
                          </span>
                        </td>
                        <td className="max-300-text">{post.title}</td>
                        <td>{post.editor.name}</td>
                        <td className="max-100-text">
                          {post.posted_at ? (
                            <Moment
                              parse="YYYY-MM-DD HH:mm:ss"
                              format="MM/DD/YYYY"
                            >
                              >{post.posted_at}
                            </Moment>
                          ) : null}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              size="sm"
                              color="link"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              aria-labelledby="dropdownMenuLink"
                              right
                            >
                              <DropdownItem
                                type="button"
                                onClick={() => this.props.goToPost(post.id)}
                              >
                                修改
                              </DropdownItem>
                              <DropdownItem
                                type="button"
                                onClick={() =>
                                  this.props.toggleDeletePostModal(post)
                                }
                              >
                                删除
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <CardFooter className="border-bottom-l">{paginationDiv}</CardFooter>
        </Card>
      </div>
    );
  }
}
