import {
  ADS_GET_ADS_REQUEST,
  ADS_GET_ADS_SUCCESS,
  ADS_GET_ADS_FAIL,
  ADS_TOGGLE_AD_MODAL,
  ADS_CREATE_AD_REQUEST,
  ADS_CREATE_AD_SUCCESS,
  ADS_CREATE_AD_FAIL,
  ADS_UPDATE_AD_REQUEST,
  ADS_UPDATE_AD_SUCCESS,
  ADS_UPDATE_AD_FAIL,
  ADS_DELETE_AD_REQUEST,
  ADS_DELETE_AD_DONE,
  ADS_GET_DISPLAY_SECTIONS_REQUEST,
  ADS_GET_DISPLAY_SECTIONS_SUCCESS,
  ADS_GET_DISPLAY_SECTIONS_FAIL,
  ADS_TOGGLE_DELETE_AD_MODAL
} from "../actions/adsAction";

const initialState = {
  loading: false,
  ads: null,
  selectedAd: {},
  errorMsg: "",
  successMsg: "",
  errors: {},
  openAdModal: false,
  savingAd: false,
  display_sections: null,
  openDeleteAdModal: false
};

const adReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADS_GET_ADS_REQUEST:
      return {
        ...state,
        loading: true,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case ADS_GET_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
        ads: action.ads,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case ADS_GET_ADS_FAIL:
      return {
        ...state,
        loading: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case ADS_TOGGLE_AD_MODAL:
      return {
        ...state,
        openAdModal: !state.openAdModal,
        selectedAd: action.selectedAd,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case ADS_CREATE_AD_REQUEST:
      return {
        ...state,
        savingAd: true,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case ADS_CREATE_AD_SUCCESS:
      return {
        ...state,
        savingAd: false,
        openAdModal: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case ADS_CREATE_AD_FAIL:
      return {
        ...state,
        savingAd: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case ADS_UPDATE_AD_REQUEST:
      return {
        ...state,
        savingAd: true,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case ADS_UPDATE_AD_SUCCESS:
      return {
        ...state,
        savingAd: false,
        openAdModal: false,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case ADS_UPDATE_AD_FAIL:
      return {
        ...state,
        savingAd: false,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case ADS_DELETE_AD_REQUEST:
      return {
        ...state,
        savingAd: true
      };
    case ADS_DELETE_AD_DONE:
      return {
        ...state,
        openAdModal: false,
        openDeleteAdModal: false,
        savingAd: false
      };
    case ADS_GET_DISPLAY_SECTIONS_REQUEST:
      return {
        ...state,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    case ADS_GET_DISPLAY_SECTIONS_SUCCESS:
      return {
        ...state,
        display_sections: action.data,
        successMsg: action.msg,
        errorMsg: "",
        errors: {}
      };
    case ADS_GET_DISPLAY_SECTIONS_FAIL:
      return {
        ...state,
        successMsg: "",
        errorMsg: action.msg,
        errors: action.errors
      };
    case ADS_TOGGLE_DELETE_AD_MODAL:
      return {
        ...state,
        openDeleteAdModal: !state.openDeleteAdModal,
        openAdModal: false,
        selectedAd: action.selectedAd,
        successMsg: "",
        errorMsg: "",
        errors: {}
      };
    default:
      return state;
  }
};

export default adReducer;
