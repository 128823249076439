import Api from "../api.request";
import { toast } from "react-toastify";

export const USER_GET_ALL_REQUEST = "USER_GET_ALL_REQUEST";
export const USER_GET_ALL_SUCCESS = "USER_GET_ALL_SUCCESS";
export const USER_GET_ALL_FAIL = "USER_GET_ALL_FAIL";

export const getUsers = ({ page, name }) => dispatch => {
  dispatch({ type: USER_GET_ALL_REQUEST });
  const params = { page, name };
  Api.request({
    url: "users",
    params,
    method: "get"
  }).then(({ data }) => {
    if (data.success) {
      var userOptions = data.data.data
        ? data.data.data.map(item => {
            return {
              value: item.id,
              label: item.name
            };
          })
        : [];
      userOptions.unshift({
        value: null,
        label: "全部编辑"
      });
      dispatch({ type: USER_GET_ALL_SUCCESS, users: data.data, userOptions });
    } else {
      dispatch({ type: USER_GET_ALL_FAIL, errorMsg: data.message });
    }
  });
};

export const USER_TOGGLE_USER_MODAL = "USER_TOGGLE_USER_MODAL";
export const toggleUserModal = user => dispatch => {
  dispatch({
    type: USER_TOGGLE_USER_MODAL,
    selectedUser: user ? user : {}
  });
};

export const USER_CREATE_USER_REQUEST = "USER_CREATE_USER_REQUEST";
export const USER_CREATE_USER_SUCCESS = "USER_CREATE_USER_SUCCESS";
export const USER_CREATE_USER_FAIL = "USER_CREATE_USER_FAIL";
export const createUser = ({
  name,
  email,
  first_name,
  last_name,
  role_id,
  active
}) => dispatch => {
  dispatch({ type: USER_CREATE_USER_REQUEST });
  Api.request({
    url: "create-user",
    data: { name, email, first_name, last_name, role_id, active },
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: USER_CREATE_USER_SUCCESS, msg: data.message });
      dispatch(getUsers({ page: 1 }));
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      dispatch({
        type: USER_CREATE_USER_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  });
};

export const USER_UPDATE_USER_REQUEST = "USER_UPDATE_USER_REQUEST";
export const USER_UPDATE_USER_SUCCESS = "USER_UPDATE_USER_SUCCESS";
export const USER_UPDATE_USER_FAIL = "USER_UPDATE_USER_FAIL";
export const updateUser = ({
  id,
  name,
  email,
  first_name,
  last_name,
  role_id,
  active
}) => dispatch => {
  dispatch({ type: USER_UPDATE_USER_REQUEST });
  Api.request({
    url: "update-user",
    data: { id, name, email, first_name, last_name, role_id, active },
    method: "POST"
  }).then(({ data }) => {
    if (data.success) {
      dispatch({ type: USER_UPDATE_USER_SUCCESS, msg: data.message });
      dispatch(getUsers({ page: 1 }));
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      dispatch({
        type: USER_UPDATE_USER_FAIL,
        msg: data.message,
        errors: data.errors ? data.errors : {}
      });
      toast.warn(data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  });
};
