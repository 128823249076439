import React, { Component } from "react";
import PropTypes from "prop-types";

class Affix extends Component {
  static propTypes = {
    offset: PropTypes.number
  };

  static defaultProps = {
    offset: 0
  };

  constructor() {
    super();
    this.state = {
      affix: false,
      width: 0,
      style: {},
      isMobile: false
    };
    this.myInput = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.setState({
      width: this.myInput.current.offsetWidth + "px"
    });

    this.isMobile();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  isMobile = () => {
    this.setState({
      isMobile: window.innerWidth <= 767
    });
  };

  handleResize = () => {
    this.isMobile();
    const { affix } = this.state;

    this.setState({
      width: this.myInput.current.parentNode.clientWidth + "px"
    });
    if (affix) {
      this.setState({
        style: { width: this.state.width }
      });
    }
  };

  handleScroll = () => {
    const affix = this.state.affix;
    const offset = this.props.offset;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (!affix && scrollTop > offset) {
      this.setState({
        affix: true
      });
      this.setState({
        style: { width: this.state.width }
      });
    }

    if (affix && scrollTop <= offset) {
      this.setState({
        affix: false,
        style: {}
      });
    }
  };

  render() {
    const affix = this.state.affix ? "affix" : "";
    const { className, ...props } = this.props;

    return (
      <div
        {...props}
        className={`${className || ""} ${affix}`}
        ref={this.myInput}
        style={this.state.style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Affix;
