import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./home";
import Users from "./users";
import Ads from "./ads";
import NewPost from "./posts/new";
import EditPost from "./posts/edit";
import Login from "./login";
import Profile from "./profile";
import ChangePassword from "./profile/password";
import ForgotPassword from "./password/create";
import ResetPassword from "./password/reset";
import Error404 from "./error/404";

const App = () => (
  <div>
    <Switch>
      <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
      <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
      <Route exact path={`${process.env.PUBLIC_URL}/users`} component={Users} />
      <Route exact path={`${process.env.PUBLIC_URL}/ads`} component={Ads} />
      <Route path={`${process.env.PUBLIC_URL}/posts/new`} component={NewPost} />
      <Route
        path={`${process.env.PUBLIC_URL}/posts/edit/:id`}
        component={EditPost}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/password/forgot`}
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/password/reset/:token`}
        component={ResetPassword}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/profile`}
        component={Profile}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/change-password`}
        component={ChangePassword}
      />
      <Route component={Error404} />
    </Switch>
  </div>
);

export default App;
