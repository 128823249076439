import React from "react";
import { Card } from "reactstrap";
import { Loader } from "react-loaders";
import "./ErrorLoadingCard.scss";

class ErrorLoadingCard extends React.Component {
  constructor(props) {
    super(props);
    this.enableMessage = this.enableMessage.bind(this);

    this.state = {
      displayMessage: false
    };

    this.timer = setTimeout(this.enableMessage, 250);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage() {
    this.setState({ displayMessage: true });
  }

  render() {
    const { displayMessage } = this.state;

    return (
      <Card className="bg-secondary shadow border-0 main-card">
        <div className="page-center">
          <Loader type="ball-beat" />
          <h5>
            {!displayMessage || this.props.loading
              ? "加载中"
              : this.props.error}
          </h5>
        </div>
      </Card>
    );
  }
}

export default ErrorLoadingCard;
